
/* ********** Titles ********** */
h2,
h3,
h4 {
    color: #3b3f4a;
}

h2 {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;
}

h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
}

h4 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.3;
}

.section-title {
    position: relative;
    padding: 20px 0 40px 0;
}

.section-title:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 6px;
    border-radius: 3px;
    background: linear-gradient(90deg,
            rgba(78, 160, 255, 1) 0%,
            rgba(134, 206, 255, 1) 100%);
}

.section-title.is-green:before {
    background: linear-gradient(90deg,
            rgba(189, 218, 106, 1) 0%,
            rgba(224, 239, 163, 1) 100%);
}

.section-title.is-purple:before {
    background: linear-gradient(90deg,
            rgba(169, 128, 254, 1) 0%,
            rgba(212, 183, 255, 1) 100%);
}

.section-title.is-orange:before {
    background: linear-gradient(90deg,
            rgba(251, 168, 92, 1) 0%,
            rgba(255, 204, 106, 1) 100%);
}

.section-title--center {
    text-align: center;
}

.section-title--center:before {
    left: 50%;
    margin-left: -40px;
}

.section-title--right {
    text-align: right;
}

.section-title--right:before {
    left: inherit;
    right: 0;
}

@media screen and (max-width: 1279px) {
    h2 {
        font-size: 44px;
    }

    h3 {
        font-size: 36px;
    }

    h4 {
        font-size: 24px;
    }
}

@media screen and (max-width: 1023px) {
    h2 {
        font-size: 34px;
    }

    h3 {
        font-size: 28px;
    }
}

@media screen and (max-width: 767px) {
    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 22px;
    }

    .section-title {
        position: relative;
        padding: 15px 0 20px 0;
    }

    .section-title:before {
        width: 60px;
        height: 4px;
        border-radius: 2px;
    }

    .section-title--center,
    .section-title--right {
        text-align: left;
    }

    .section-title--center:before,
    .section-title--right:before {
        left: 0;
        right: inherit;
        margin-left: 0;
    }
}