
/* ------------------------------------------------------------ */
/* HOW - WHY - WHO: LONG BLOCK WITH BGD ILLUSTRATED ON SCROLL */
/* ------------------------------------------------------------ */
.how-why-who {
    background: url('~@website-assets/img/how-why-who__clouds-bgd@2x.png'),
        linear-gradient(0deg, #f3faff 0%, #bbe9ff 100%);
    background-repeat: repeat-x, no-repeat;
    background-size: 800px 200px, cover;
    padding: 140px 0 80px 0;
}

@media screen and (max-width: 1279px) {
    .how-why-who {
        padding: 140px 0 0 0;
    }
}

@media screen and (max-width: 1023px) {
    .how-why-who {
        background-size: 600px 150px, cover;
        padding: 120px 0 0;
    }
}

@media screen and (max-width: 767px) {
    .how-why-who {
        background-size: 400px 100px, cover;
        padding: 80px 0 0;
    }
}

/* ********** HOW ********** */
.how__head {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}

.how__callout-btn {
    display: block;
    height: 44px;
    margin: 27px 0 0 0;
}

#how__tabs {
    width: 1200px;
    padding: 10px;
    margin: 0 auto 100px auto;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 30px 50px -50px rgba(16, 88, 221, 0.5),
        0px 4px 16px 0px rgba(9, 31, 101, 0.15);
}

.how__tabs-nav {
    float: left;
    width: 440px;
}

.how__tabs-nav li {
    position: relative;
    clear: left;
    width: 100%;
    list-style: none;
}

.how__tabs-nav a {
    display: block;
    padding: 15px 80px 15px 30px;
    margin: 20px 0 10px 0;
    text-decoration: none;
    color: #718ea3;
    font-size: 18px;
    line-height: 1.3;
}

.how__tabs-nav a:hover {
    color: #082fae;
}

.how__tabs-nav .ui-tabs-active a,
.how__tabs-nav .ui-tabs-active a:hover {
    color: #242732;
    font-size: 22px;
    cursor: default;
}

.how__tabs-nav a:before {
    content: "";
    display: block;
    width: 40px;
    height: 3px;
    position: absolute;
    top: 0;
    left: 30px;
    border-radius: 1.5px;
    background: linear-gradient(90deg, #85ceff 0%, #bbe9ff 100%);
    transition: width 0.3s ease;
}

.how__tabs-nav .ui-tabs-active a:before,
.how__tabs-nav a:hover:before {
    width: 60px;
    background: linear-gradient(90deg, #7a4fd4 0%, #85ceff 100%);
}

.how__tabs-nav a .how__tabs-nav-number {
    display: none;
    position: absolute;
    right: -10px;
    font-size: 80px;
    line-height: 80px;
    top: 50%;
    margin-top: -35px;
    color: rgba(77, 160, 255, 0.13);
    font-weight: 700;
    text-align: right;
}

.how__tabs-nav .ui-tabs-active a .how__tabs-nav-number {
    color: rgba(77, 160, 255, 1);
}

.how__tab {
    width: 740px;
    float: right;
    position: relative;
    z-index: 10;
}

.how__tab img { 
    display: block; 
    width: 100%; 
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(16,88,221,0.3);
}

.how__callout-btn--mobile {
    display: none;
}

@media screen and (max-width: 1279px) {
    #how__tabs {
        width: 904px;
    }

    .how__tabs-nav {
        width: 324px;
    }

    .how__tabs-nav a {
        padding: 12px 45px 12px 15px;
        margin: 15px 0 10px 0;
        font-size: 13px;
    }

    .how__tabs-nav .ui-tabs-active a,
    .how__tabs-nav .ui-tabs-active a:hover {
        font-size: 18px;
    }

    .how__tabs-nav a:before {
        width: 30px;
        left: 15px;
    }

    .how__tabs-nav a .how__tabs-nav-number {
        right: -6px;
        font-size: 60px;
        line-height: 60px;
        margin-top: -25px;
    }

    .how__tabs-nav .ui-tabs-active a .how__tabs-nav-number {
        right: -10px;
        font-size: 80px;
        line-height: 80px;
        margin-top: -35px;
    }

    .how__tab {
        width: 560px;
    }
}

@media screen and (max-width: 1023px) {
    .how__callout-btn:before {
        bottom: 11px;
    }

    #how__tabs {
        width: 688px;
        margin: 0 auto 70px auto;
        display: flex;
        flex-direction: column;
    }

    .how__tab {
        width: 668px;
    }

    .how__tabs-nav {
        width: 100%;
        order: 10;
        position: relative;
        padding: 0 0 0 40px;
        display: block;
    }

    .how__tabs-nav li {
        position: initial;
        display: inline-block;
        width: auto;
        z-index: 1;
    }

    .how__tabs-nav li.ui-tabs-active {
        z-index: 0;
    }

    .how__tabs-nav a {
        display: inline-block;
        padding: 10px 9px 0 9px;
        margin: 0;
    }

    .how__tabs-nav a:before {
        display: none;
    }

    .how__tabs-nav a .how__tabs-nav-number {
        position: inherit;
        right: 0;
        font-size: 50px !important;
        line-height: 60px !important;
        top: 0;
        margin-top: 2px;
    }

    .how__tabs-nav-text {
        position: absolute;
        display: none;
    }

    .how__tabs-nav li.ui-tabs-active .how__tabs-nav-text {
        display: block;
        left: 260px;
        width: 360px;
        top: 17px;
    }
}

@media screen and (max-width: 767px) {
    .how__head {
        padding: 0;
    }

    .how__callout-btn {
        display: none;
    }

    #how__tabs {
        width: 100%;
        margin: 0 auto 20px auto;
        padding: 5px;
        border-radius: 20px;
    }

    .how__tab {
        width: 100%;
    }

    .how__tab img {
        border-radius: 15px;
    }

    .how__tabs-nav {
        padding: 0 0 60px 0;
        text-align: center;
    }

    .how__tabs-nav a .how__tabs-nav-number {
        font-size: 36px !important;
        margin-top: -7px;
    }

    .how__tabs-nav li.ui-tabs-active .how__tabs-nav-text {
        left: 15px;
        right: 15px;
        width: auto;
        top: 60px;
        font-size: 16px;
    }

    .how__callout-btn--mobile {
        display: block;
        margin: 0 auto 50px auto;
        text-align: center;
    }

    .how__callout-btn--mobile a {
        display: inline-block;
    }
}

/* ********** WHY ********** */
.why {
    margin: 0 0 100px 0;
}

.why .section-title {}

.why__list {
    display: flex;
    justify-content: space-between;
}





.why__point h4 {
    text-align: center;
}

.why__point p {
    font-size: 20px;
    padding: 12px 0 0 0;
}

.why__callout-wrapper {
    text-align: center;
    margin: 40px 0 0;
}

@media screen and (max-width: 1279px) {

    .why__point p {
        font-size: 16px;
    }

    .why__callout-wrapper {
        padding: 40px 0;
    }
}

@media screen and (max-width: 1023px) {
    .why {
        margin: 0 0 70px 0;
    }

    .why__list {
        flex-direction: column;
    }

    .why__point h4 {
        text-align: left;
    }

    .why__point p {
        font-size: 16px;
        padding: 8px 0 0 0;
    }

    .why__callout-wrapper {
        margin: 0;
    }
}

@media screen and (max-width: 767px) {
    .why {
        margin: 0 0 10px 0;
    }

    .why__point {
        padding: 80px 20px 20px 20px;
        margin-bottom: 50px;
        border-radius: 20px;
    }

    .why__point h4 {
        text-align: center;
    }

    .why__point p {
        font-size: 14px;
    }

    .why__callout-btn {
        top: -35px;
    }
}

/* ********** WHO ********** */
.who {}

.who .section-title {
    right: 30px;
}

.who__excerpt {
    padding: 0 0 0 630px;
    position: relative;
    top: -20px;
    margin: 0 0 20px 0;
}

.who__cards {
    display: flex;
    justify-content: space-between;
}

.who__card {
    background-color: transparent;
    width: 270px;
    height: 380px;
    perspective: 1000px;
    list-style: none;
}

.who__card-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 16px 0 rgba(9, 31, 101, 0.15);
    border-radius: 30px;
}

.who__card:hover .who__card-wrapper {
    transform: rotateY(180deg);
}

.who__card-front,
.who__card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.who__card-front {
    padding: 20px 20px 0 20px;
    background-color: #fff;
    border-radius: 30px;
}

.who-card__avatar {
    display: block;
    width: 228px;
    height: 228px;
    margin: 4px 1px 0 1px;
    position: relative;
    border-radius: 11px;
    transition: opacity 0.6s ease;
    z-index: 2;
}

.who__card:hover .who-card__avatar {
    opacity: 0;
}

.who-card__avatar:before {
    content: "";
    position: absolute;
    width: 230px;
    height: 230px;
    top: -4px;
    left: -1px;
    background-size: cover;
    border-radius: 10px;
}

.who-card__main-info {
    height: 80px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.who-card__name {
    font-size: 24px;
    color: #4a6272;
    line-height: 1.1;
    font-weight: 600;
    margin: 0 0 5px 0;
    letter-spacing: 1px;
}

.who-card__role {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    color: #718ea3;
}

.who__card-back {
    padding: 20px;
    color: #4a6272;
    border-radius: 30px;
    transform: rotateY(180deg);
    box-shadow: 0 0 0 5px #fff inset;
    text-align: left;
    font-size: 18px;
}

.who__card-back p {
    padding: 5px 0;
}



.who__card-blue .who__card-back {
    background: linear-gradient(90deg, #dcecff 0%, #e7f6ff 100%);
}

.who__card-blue .who-card__avatar {
    background: linear-gradient(90deg,
            rgba(78, 160, 255, 1) 0%,
            rgba(134, 206, 255, 1) 100%);
}

.who__card-orange .who__card-back {
    background: linear-gradient(90deg, #fce5d1 0%, #fef0d6 100%);
}

.who__card-orange .who-card__avatar {
    background: linear-gradient(90deg,
            rgba(251, 168, 92, 1) 0%,
            rgba(255, 204, 106, 1) 100%);
}

.who__card-green .who__card-back {
    background: linear-gradient(90deg, #f2f8e2 0%, #f9fced 100%);
}

.who__card-green .who-card__avatar {
    background: linear-gradient(90deg,
            rgba(189, 218, 106, 1) 0%,
            rgba(224, 239, 163, 1) 100%);
}

.who__card-purple .who__card-back {
    background: linear-gradient(90deg, #eee6ff 0%, #f7f1ff 100%);
}

.who__card-purple .who-card__avatar {
    background: linear-gradient(90deg,
            rgba(169, 128, 254, 1) 0%,
            rgba(212, 183, 255, 1) 100%);
}

@media screen and (max-width: 1279px) {
    .who__excerpt {
        padding: 0 30px 0 360px;
    }

    .who__cards {
        flex-wrap: wrap;
        padding: 0 0 60px 330px;
    }

    .who__card {
        margin: 0 0 30px 0;
    }
}

@media screen and (max-width: 1023px) {
    .who__excerpt {
        padding: 0 0 0 208px;
    }

    .who__cards {
        padding: 0 0 50px 0;
    }

    .who__card {
        width: 329px;
        height: 360px;
    }

    .who-card__avatar {
        width: 287px;
    }

    .who-card__avatar:before {
        width: 289px;
    }

    .who-card__main-info {
        height: 70px;
    }
}

@media screen and (max-width: 767px) {
    .who .section-title {
        left: 20px;
    }

    .who__excerpt {
        padding: 0 20px;
        top: -10px;
    }

    .who__card {
        height: 132px;
        width: 100%;
        margin: 0 0 20px 0;
    }

    .who__card:hover {
        height: 150px;
    }

    .who__card-wrapper {
        border-radius: 20px;
    }

    .who__card-front {
        padding: 5px;
        border-radius: 20px;
        display: flex;
    }

    .who-card__avatar {
        width: 118px;
        height: 118px;
        flex: 0 0 118px;
        border-radius: 16px;
    }

    .who-card__avatar:before {
        width: 120px;
        height: 120px;
        border-radius: 15px;
    }

    .who-card__main-info {
        height: 120px;
        margin: 0 20px;
        text-align: left;
        flex: 1;
    }

    .who-card__name {
        font-size: 20px;
    }

    .who-card__role {
        font-size: 14px;
    }

    .who__card-back {
        padding: 10px 15px;
        border-radius: 20px;
        font-size: 14px;
    }
}

@media screen and (max-width: 500px) {
    .who__card:hover {
        height: 185px;
    }
}

@media screen and (max-width: 374px) {
    .who__card:hover {
        height: 210px;
    }
}