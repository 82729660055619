$gb: #F2EEE8;
$wh: #FFFFFF;
$dark: #383A41;
$red: #EB6D6D;
$blue: #5E7FDC;

$break: 400px;

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	min-height: 100vh;
	background-color: $gb;
}

.container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: space-around;
    align-items: center;
    .error {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        .subtitle {
            text-align: center;
        }
    }
}

.padd {
	padding: 40px;
}

.starry-thanks {
	width: 300px; 
	height: auto;
	padding-top: 200px;
}

h1 {
	font-weight: 800;
	color: $dark;
	text-align: center;
	font-size: 2.5em;
	padding-top: 20px;
	
	@media screen and (max-width: $break) {
		padding-left: 20px;
		padding-right: 20px;
		font-size: 2em;
  	}
}

