/* ------------------------------------------------------------ */
/* GLOBAL */
/* ------------------------------------------------------------ */

/* ********** Global style ********** */
html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

body {
    font-size: 20px;
    line-height: 1.5;
    color: #718ea3;
    font-family: "Baloo 2", sans-serif;
}

.clear {
    clear: both;
}

.section-wrapper {
    width: 1200px;
    margin: 0 auto;
}

q {
    quotes: "« " " »";
}

@media screen and (max-width: 1279px) {
    body {
        font-size: 16px;
    }

    .section-wrapper {
        width: 904px;
    }
}

@media screen and (max-width: 1023px) {
    .section-wrapper {
        width: 688px;
    }
}

@media screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }

    .section-wrapper {
        width: 100%;
        padding: 0 20px;
    }
}

.locale-changer {
    display:none;
}

// .main-content {
//     h2, h3 {
//         @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-l) {
//             font-size: 45px;
//         }
//         @media screen and (max-width: $breakpoint-m) {
//             font-size: 30px;
//         }
//     }

// }