


/* ********** Forms ********** */
input[type="text"],
input[type="email"],
input[type="password"] {
    background: #fff;
    line-height: 60px;
    height: 60px;
    font-size: 18px;
    padding: 0 20px;
    width: 100%;
    border-radius: 15px;
    font-family: "Baloo 2", sans-serif;
    transition: 0.3s ease;
}

input[type="text"]+.input__gradient-bgd,
input[type="email"]+.input__gradient-bgd,
input[type="password"]+.input__gradient-bgd {
    display: block;
    position: absolute;
    width: 100%;
    height: 62px;
    top: 1px;
    left: 0;
    border-radius: 15px;
    background: linear-gradient(to left, #5027a6, #4ea0ff);
    z-index: -1;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    outline: none;
    margin-top: -3px;
}

input[type="text"]:focus+.input__gradient-bgd,
input[type="email"]:focus+.input__gradient-bgd,
input[type="password"]:focus+.input__gradient-bgd {
    box-shadow: 0px 12px 10px -15px rgba(122, 79, 212, 0.5),
        0px 2px 8px 0px rgba(169, 128, 254, 0.4);
}

button {
    font-family: "Baloo 2", sans-serif;
}

@media screen and (max-width: 767px) {

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        line-height: 44px;
        height: 44px;
        font-size: 14px;
        padding: 0 12px;
        border-radius: 10px;
    }

    input[type="text"]+.input__gradient-bgd,
    input[type="email"]+.input__gradient-bgd,
    input[type="password"]+.input__gradient-bgd {
        height: 46px;
        border-radius: 10px;
    }

    input[type="text"]:focus,
    input[type="email"]:focus,
    input[type="password"]:focus {
        outline: none;
        margin-top: -2px;
    }
}