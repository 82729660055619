/* ------------------------------------------------------------ */
/* HEADER */
/* ------------------------------------------------------------ */
.main-head {
  position: relative;
  text-align: center;
  padding: 30px 60px 60px 60px;
}

.main-head {
  background-image: url('~@website-assets/img/Head-clouds-bgd@2x.png'),
    url('~@website-assets/img/Head-Moon@2x.png'),
    url('~@website-assets/img/Head-Stars@2x.png'),
    url('~@website-assets/img/Head-bgd.jpg');
  background-repeat: repeat-x, no-repeat, no-repeat, no-repeat;
  background-size:
    1000px 450px,
    1200px 600px,
    2000px 400px,
    cover;
  background-position:
    center bottom,
    center top -120px,
    center top -140px,
    center;
}

.section-wrapper {
  position: relative;
  z-index: 10;
}

.header__logo img {
  display: block;
  height: 130px;
  margin: 30px auto 30px auto;
}

.main-head__title {
  display: inline-flex;
  font-size: 55px;
  color: #fff;
  text-shadow: -1px 3px 1px rgba(26, 41, 77, 0.5);
  letter-spacing: 1px;
  font-weight: 500;
}

.main-head__title span {
  color: #f7e68e;
}

.main-head__descr {
  color: #fff;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 1px;
  text-shadow: -1px 3px 1px rgba(26, 41, 77, 0.5);
  font-weight: 500;
  max-width: 840px;
  margin: 25px auto 25px auto;
}

.main-head__descr span {
  color: #f7e68e;
  font-weight: 600;
}

.main-head__callout-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 350px;
  margin: 0 auto;
}

.main-head__callout-btns a {
  width: 100%;
  box-sizing: border-box;
}

.kid-name {
  color: #f7e68e;
}

/******** Menu ********/

.menu {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  background-color: white;
  transition: height 0.5s ease;
  overflow: hidden;
  height: 0;
  box-shadow: 1px 1px 15px rgba(26, 41, 77, 0.2);
}

.menu-logo {
  height: 60px;
  left: 150px;
  width: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.menu-links {
  position: absolute;
  right: 150px;
  top: 50%;
  transform: translateY(-50%);
}

.menu-flex {
  display: flex;
}

.connect-menu-btn {
  position: sticky;
  margin: 10px;
}

.menu-link {
  color: #a880fe;
  margin: 10px;
  font-size: 20px;
}
.menu-fixed {
  top: 30px;
  z-index: 100;
  background: linear-gradient(to right, #4da0ff, #6ab8ff);
  border-radius: 40px;
  padding: 5px 10px 5px 16px;
}

.subscribe-menu-btn {
  border-radius: 40px;
  padding: 5px 10px 5px 16px;
  border: 1px solid;
  text-decoration: none;
}

.menu-fixed .menu-link {
  color: white;
  text-decoration: none;
  position: relative;
  top: 1px;
}

.lone-star {
  animation: rotateAndScaleStar 5s linear infinite;
  display: inline-block;
  vertical-align: middle;
  width: 15px;
}

@keyframes rotateAndScaleStar {
  0% {
    transform: rotate(0deg) scale(1);
    filter: drop-shadow(0 0 1px yellow);
  }
  25% {
    transform: rotate(90deg) scale(1.2);
    filter: drop-shadow(0 0 4px yellow);
  }
  50% {
    transform: rotate(180deg) scale(1);
    filter: drop-shadow(0 0 8px yellow);
  }
  75% {
    transform: rotate(270deg) scale(0.8);
    filter: drop-shadow(0 0 4px yellow);
  }
  100% {
    transform: rotate(360deg) scale(1);
    filter: drop-shadow(0 0 1px yellow);
  }
}

@media screen and (max-width: 1023px) {
  .menu-logo {
    left: 100px;
  }

  .menu-links {
    right: 100px;
  }
}

@media screen and (max-width: 767px) {
  .menu-logo {
    height: 40px;
    left: 50px;
  }

  .menu-links {
    right: 50px;
  }

  .menu-link {
    font-size: 15px;
  }
}

@media screen and (max-width: 1279px) {
  .main-head__descr {
    font-size: 20px;
    max-width: 688px;
  }

  .main-head {
    background-size:
      1000px 450px,
      900px 450px,
      1250px 250px,
      cover;
    background-position:
      center bottom,
      center top -70px,
      center top -50px,
      center;
  }
}

@media screen and (max-width: 1023px) {
  .main-head {
    padding: 30px 0 0 0;
    background-size:
      500px 275px,
      600px 300px,
      1000px 200px,
      cover;
    background-position:
      center bottom,
      right 100px top -20px,
      center top -50px,
      center;
  }

  .main-head__title {
    font-size: 40px;
  }

  .main-head__callout-btns {
    padding: 0 0 40px 0;
  }

  .parallax-cloud {
    height: 400px;
    background-size: 910px 400px;
  }

  .parallax-moon {
    width: 750px;
    height: 375px;
    top: -80px;
    margin-left: -400px;
  }

  .menu-links {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .main-head {
    padding: 30px 0 0 0;
    background-size:
      500px 275px,
      500px 250px,
      1000px 200px,
      cover;
    background-position:
      center bottom,
      right 40px top -10px,
      center top -50px,
      center;
  }

  .header__logo img {
    height: 100px;
  }

  .main-head__title {
    font-size: 25px;
    letter-spacing: 0;
    display: block;
  }

  .main-head__title span {
    display: block;
  }

  .kid-name {
    display: block;
    height: 25px; // equals font-size, todo : replace it by a sass variable
    margin-bottom: 8px;
  }

  .main-head__descr {
    font-size: 16px;
    letter-spacing: 0.2px;
  }

  .main-head__title,
  .main-head__descr {
    text-shadow: -1px 1px 1px rgba(26, 41, 77, 0.5);
  }

  .main-head__callout-btns {
    padding: 0 0 30px 0;
    width: 200px;
  }

  .main-head__callout-btns a {
    margin: 0 5px;
  }

  .parallax-cloud {
    height: 275px;
    background-size: 500px 275px;
  }

  .parallax-moon {
    width: 500px;
    height: 250px;
    margin-left: -350px;
  }

  .parallax-stars {
    width: 1000px;
    height: 200px;
    top: -15px;
    margin-left: -500px;
  }
  .menu-links {
    right: 30px;
  }
}

@media screen and (max-width: 374px) {
  .main-head {
    background-position:
      center bottom,
      right 20px top -10px,
      center top -50px,
      center;
  }
  .header__logo img {
    height: 60px;
  }
  .main-head__callout-btns {
    width: 140px;
  }
}

.parallax-bgd {
  .Masthead__image {
    height: 100% !important;
  }
}

@media screen and (max-width: 374px) {
  .kid-name {
    display: block;
  }
}

//// FOURRE TOUT DE SECOURS

@media screen and (max-width: 1279px) {
  .main-head__descr {
    font-size: 20px;
    max-width: 688px;
  }

  .parallax-moon {
    width: 900px;
    height: 450px;
    top: -120px;
    margin-left: -530px;
  }
  .parallax-stars {
    width: 1250px;
    height: 250px;
    top: -30px;
    margin-left: -625px;
  }
}

@media screen and (max-width: 1023px) {
  .main-head__title {
    font-size: 40px;
  }

  .main-head__callout-btns {
    padding: 0 0 40px 0;
  }

  .parallax-cloud {
    height: 400px;
    background-size: 910px 400px;
  }
  .parallax-moon {
    width: 750px;
    height: 375px;
    top: -80px;
    margin-left: -400px;
  }
}

@media screen and (max-width: 767px) {
  //.header__logo img { width: 121px; height: 78px;}
  .main-head__title {
    font-size: 25px;
    letter-spacing: 0;
  }
  .main-head__title span {
    display: block;
  }
  .main-head__descr {
    font-size: 16px;
    letter-spacing: 0.2px;
  }
  .main-head__title,
  .main-head__descr {
    text-shadow: -1px 1px 1px rgba(26, 41, 77, 0.5);
  }

  .main-head__callout-btns {
    padding: 0 0 30px 0;
  }
  .main-head__callout-btns a {
    margin: 0 5px;
  }

  .parallax-cloud {
    height: 275px;
    background-size: 500px 275px;
  }
  .parallax-moon {
    width: 500px;
    height: 250px;
    margin-left: -350px;
  }
  .parallax-stars {
    width: 1000px;
    height: 200px;
    top: -15px;
    margin-left: -500px;
  }
}

.main-head {
  .section-wrapper {
    .starry {
      position: absolute;
      z-index: 0;
      bottom: 0px;
      transform: translateY(30%);
      left: 30px;
      @media screen and (max-width: $breakpoint-xl) {
        transform: translateY(10%);
        left: 100px;
      }
      @media screen and (max-width: $breakpoint-l) {
        transform: translateY(-10%);
        left: 30px;
      }
      @media screen and (max-width: $breakpoint-m) {
        transform: translateY(0);
        left: 50px;
      }

      @media screen and (max-width: $breakpoint-sm) {
        left: 0;
      }
      img {
        height: 300px;
        @media screen and (max-width: $breakpoint-xl) {
          height: 200px;
        }
        @media screen and (max-width: $breakpoint-m) {
          height: 150px;
        }
        @media screen and (max-width: $breakpoint-sm) {
          height: 125px;
        }
      }
    }
  }
}
