@import '../../style/vars';

.lottie {
    align-items: center;
    align-content: center;
}

h4 {
    text-align: center;
}

h5 {
    text-align: center;
}

.btns {
    display: block;
    align-items: center;
}

.card {
    padding: 12px 30px 24px 30px;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0.4px 3.6px rgba(0, 0, 0, 0.004),
        0 1px 8.5px rgba(0, 0, 0, 0.01), 0 1.9px 15.7px rgba(0, 0, 0, 0.019),
        0 3.4px 28.2px rgba(0, 0, 0, 0.03), 0 6.3px 54.4px rgba(0, 0, 0, 0.047),
        0 15px 137px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 400px;

    @media screen and (max-width: $breakpoint-m) {
        min-width: inherit;
    }

    #lottie{
        width: 300px; 
        height: 300px;

        @media screen and (max-width: $breakpoint-m) {
            width: 250px;
            height: 250px;
        }

    }
    .title {
        align-items: center;
        font-size: 28px;
        font-weight: 400;
        margin-bottom: 4px;
        position: relative;
        &.top {
            padding: 12px;
        }
        &.error {
            color: #ff4f4a;
        }
        @media screen and (max-width: $breakpoint-m) {
            font-size: 23px;
        }
    }
    .text {
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        font-weight: 400;
        .info {
            color: #64686b;
        }
    }
    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 8px;
        width: 100%;

        .button {
            align-items: center;
            background: #edf1f7;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            height: 50px;
            justify-content: center;
            width: 100%;
            &.button-primary {
                background-color: #84a9f3;
                ;
                color: #fff;
            }
            &.disabled {
                opacity: 0.5;
                cursor: default;
            }
        }
        
    }
}





