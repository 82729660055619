
  .confidentiality {
    background-image: url('~@common-assets/mixed-clouds-bg.png'),
      linear-gradient(#204b8d, #bfeaff);
    background-size: 100%, cover;
    background-position: bottom, center;
    background-repeat: no-repeat, no-repeat;
    min-height: 100vh;
    margin: 0;
    padding: 0;

    .container {
      max-width: 800px;
      margin: 0 auto;
      padding: 36px 20px;
    }

    h1,
    h2 {
      color: #3b3f4a;
      font-family: 'Baloo 2', sans-serif;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 36px;
      font-weight: 600;
      text-align: center;
      margin-top: 40px;
      line-height: 1.3;
    }

    h2 {
      font-size: 28px;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      color: #4a4a4a;
      margin-bottom: 20px;
      text-align: justify;
    }

    .content {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .page-main {
      background-color: #f9f9f9;
      border-radius: 8px;
      padding: 36px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    }
  }
