/* ------------------------------------------------------------ */
/* COOKIES */
/* ------------------------------------------------------------ */
.cookies { 
    position: fixed;
    z-index: 1000000;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(9, 31, 101, .7);
}

.cookie-option {
    display:flex;
    align-items: center;
}
    .cookies__wrapper { 
        width: 1000px; 
        max-height: 80vh;
        overflow: auto;
        background: #fff;
        padding: 50px;
        border-radius: 30px;
        box-shadow: 
            0px 30px 50px -50px rgba(16,88,221,0.5), 
            0px 4px 16px 0px rgba(9,31,101,0.15);
    }
        .cookies__title { padding: 0 0 20px 0;}
        .cookies__intro { padding: 0 0 20px 0;}
        .cookies--short { align-items: center;}
            .cookies-short__options { flex: 1; display:flex}
                .cookies-short__options li { display: inline-block; list-style: none;}
                    .cookies-short__options input {cursor:pointer;}
                    .cookies-short__options label { margin: 0 20px 0 5px;}
                    .cookies-short__options :checked ~ label { font-weight: 600; color: #3B3F4A;}

                
            .cookies__call-btns { flex: 0 0 380px; display: flex; justify-content: space-between;}
                .cookies__call-btns .btn { flex: 0 0 250px;}
            
        .cookies--long { display: none;}
            .cookies__section { position: relative; margin: 30px 0; padding: 0 0 0 40px; list-style: none;}
                .cookies__section input { position: absolute; top: 10px; left: 0;}
                .cookies__section h5 { font-size: 25px; color: #3B3F4A; margin: 10px 0 5px 0;}
                .cookies__section h6 { font-size: 20px; color: #4A6272; margin: 0 0 5px 0;}
                .cookies__section p { margin: 0 0 5px 0;}
                .cookies__section ul {}
                    .cookies__section li { margin: 0 0 5px 0;}
                .cookies--long .cookies__call-btns { justify-content: flex-end;}
                    .cookies--long .cookies__call-btns .btn { flex: 0 0 300px; margin: 0 0 0 20px;}

.is-expanded .cookies--short { display: none;}
.is-expanded .cookies--long { display: block;}
.cookies.is-accepted { display: none;}

.cookies--long-checkbox {
    margin-right: 10px;
    cursor:pointer;
}

.cookie-section {
    margin-bottom: 30px;
}

.cookie-list {
    margin-left: 20px;
}

.cookie-trigger {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    background-color: white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 24px;
    z-index: 1000;
  }

  .cookie-emoji {
    font-size: 24px;
    transition: transform 0.5s ease-in-out;
  }
  
  .cookie-trigger:hover .cookie-emoji {
    animation: rotate 2s infinite linear;
  }


  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
@media screen and (max-width: 1279px) {
    .cookies__wrapper { width: 900px; padding: 40px;}
    .cookies__call-btns { flex: 0 0 335px;}
    .cookies__call-btns .btn { flex: 0 0 200px;}
}

@media screen and (max-width: 1023px) { 
    .cookies__wrapper { width: 688px; padding: 30px;}
    .cookies-short__options { align-items: flex-end;}
    .cookies-short__options li { display: block; margin: 0 0 5px 0;}
}

@media screen and (max-width: 767px) {
    .cookies { padding: 0 20px}
    .cookies__wrapper { max-width: 100%; padding: 20px; border-radius: 20px;}
    .cookies__title { padding: 0 0 10px 0;}
    .cookies__intro { padding: 0 0 10px 0;}
    
    .cookies--short { flex-direction: column; align-items: flex-start;}
    .cookies-short__options label { margin: 0 10px;}
    .cookies__call-btns { flex: 1; flex-wrap: wrap; width: 100%;}
    .cookies__call-btns .btn { flex: 0 0 100%; margin: 10px 0 0 0;}

    .cookies__section { margin: 20px 0; padding: 0 0 0 30px;}
    .cookies__section input { top: 10px; left: 0;}
    .cookies__section h5 { font-size: 18px;}
    .cookies__section h6 { font-size: 16px;}
    .cookies--long .cookies__call-btns .btn { flex: 0 0 100%; margin: 10px 0 0 0;}
}