
.who__cards {
    display: flex;
    justify-content: center;
    padding: 0;
    .who__card {
        padding: 0 6px;
        flex: 1;
        @media screen and (max-width: $breakpoint-xl) {
            flex: 50%;
        }
        @media screen and (max-width: $breakpoint-m) {
            flex: 100%;
        }
        .who-card__avatar {
            width: 100%;
            &:before {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: $breakpoint-xl) and (min-width: $breakpoint-l) {
        padding: 0 100px;
    }

}